<template>
  <div class="hall-related">
    <div class="related-box clearfix" v-infinite-scroll="load">
      <div class="related-item" v-for="index in count">
        <div class="related-name">某某某</div>
        <div class="related-avter">
          <img src="../../../assets/images/hall/hall-index-banner2.png">
        </div>
        <div class="related-process">
          <span class="sea-iconfont icon-bianji"></span>
          <span class="sea-iconfont">&#xe656;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'hall-related',
    data() {
      return {
        count: 16
      }
    },
    methods: {
      load () {
        this.count += 4
      }
    }
  }
</script>

<style lang="scss" scoped>
.hall-related {
  .related-box {
    .related-item {
      width: 23%;
      padding: 0 16px;
      height: 180px;
      background: #FFFFFF;
      border: 1px solid #DCDCDC;
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
      .related-name {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #444444;
        margin: 3px 0;
      }
      .related-process {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .sea-iconfont {
          color: #444444;
          font-size: 18px;
          cursor: pointer;
        }:first-child {margin-right: 14px}:last-child {font-size: 22px}
      }
      .related-avter {
        width: 100%;
        height: 118px;
        overflow: hidden;
        img {
          margin: 0 auto;
          width: 100%;
          height: 100%;
        }
      }
    }:nth-child(4n) {margin-right: 0}
  }
}
</style>
